<script setup>

    import {Swiper, SwiperSlide} from 'swiper/vue';

    import 'swiper/css';
    import 'swiper/css/pagination';
    import {FreeMode} from 'swiper/modules';

    const modules = ref([FreeMode])

    const swipperBreakpoints = {
        '0': {
            slidesPerView: 1.7,
            spaceBetween: 15,
        },
        '380': {
            slidesPerView: 1.9,
            spaceBetween: 15,
        },
        '420': {
            slidesPerView: 2.1,
            spaceBetween: 15,
        },
        '500': {
            slidesPerView: 2.5,
            spaceBetween: 20,
        },
        '600': {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        '804': {
            slidesPerView: 4,
            spaceBetween: 20,
        },
        '980': {
            slidesPerView: 5,
            spaceBetween: 25,
        },
        '1240': {
            slidesPerView: 6,
            spaceBetween: 25,
        },
    }

    const { offertype } = defineProps({
        offertype: {
            type: String,
            default: null,
        }
    })
    const { prodaja } = useAppConfig()
    const items = prodaja.types
    
    const createLink = item => {
        return (offertype) ? `/${offertype}/${item.slug}` : false
    }

    const router = useRouter()
    
</script>
<template>
    <div class="md:grid md:grid-cols-3 grid-cols-1 w-full gap-6 mt-6 hidden">
        <div v-for="item in items">
            <div>
                <nuxt-link :to="createLink(item)">
                    <div class="flex items-center justify-center w-full h-[160px] group md:hover:scale-[1.1] md:hover:z-10 transition-transform">
                        <div class="relative brightness-50 w-full h-full md:group-hover:brightness-100">
                                <div 
                                    :style="{ backgroundImage: `url('/img/on_sell/${item.image}')` }"
                                    class="rounded-xl w-full h-full bg-no-repeat bg-cover bg-center"
                                >
                                </div>
                        </div>
                        <!-- <div class="text-white font-bold text-xl rounded-lg px-2 absolute z-10 group-hover:bg-[#ffffffe3] group-hover:text-brand-grey ">
                            {{ capitalize( item.label ) }}
                            
                        </div> -->
                        <div class="text-white font-bold text-xl absolute z-10 flex items-center justify-center flex-col group-hover:text-opacity-100 ">
                            <span class="group-hover:bg-[#ffffffe3] group-hover:text-brand-grey rounded-lg px-2 text-center">
                                {{ capitalize( item.label ) }}
                            </span>
                            <div class="flex gap-4 mt-3 justify-center" v-if="!offertype">
                                <nuxt-link :to="`/arenda/${item.slug}`" class="text-base font-light md:hover:underline md:group-hover:bg-brand-primary md:group-hover:text-black md:group-hover:px-2 md:p-0 p-3 md:bg-transparent bg-brand-primary md:text-white text-black md:rounded-none rounded-lg" >Аренда</nuxt-link>
                                <nuxt-link :to="`/prodaja/${item.slug}`" class="text-base font-light md:hover:underline md:group-hover:bg-brand-green md:group-hover:text-black md:group-hover:px-2 md:p-0 p-3 md:bg-transparent bg-brand-green  md:text-white text-black md:rounded-none rounded-lg" >Продажа</nuxt-link>
                            </div>
                        </div>
                    </div>
                </nuxt-link>
            </div>
        </div>
    </div>
    <div class="md:hidden block mt-6">
        <swiper
            :slidesPerView="6"
            :spaceBetween="30"
            :modules="modules"
            class="mySwiper"
            :breakpoints="swipperBreakpoints"
        >
            <swiper-slide v-for="item in items" class=" ">
                <nuxt-link :to="`/${offertype}/${item.slug}`"
                    class="overflow-hidden min-h-[211px] w-[170px] h-full relative rounded-xl bg-cover bg-no-repeat flex flex-col justify-between cursor-pointer hover:brightness-100 brightness-[.85] "
                    :style="{ backgroundImage: `url('/img/on_sell/${item.image}')` }"
                >
                    <div class="bg-[#2C2C2CB8] rounded-t-2xl p-2 text-white h-[60px] z-10">
                        <p class="text-sm text-center flex flex-column justify-center items-center h-full"> {{ capitalize( item.label ) }}</p>
                    </div>
                    <div class="flex gap-3 justify-center items-center pb-2">
                        <nuxt-link :to="`/arenda/${item.slug}`" class="text-sm font-light underline py-1 px-2.5  bg-brand-primary md:text-white text-black md:rounded-none rounded-lg" >Аренда</nuxt-link>
                        <nuxt-link :to="`/prodaja/${item.slug}`" class="text-sm font-light underline py-1 px-2.5 bg-brand-green  md:text-white text-black md:rounded-none rounded-lg" >Продажа</nuxt-link>
                    </div>
                </nuxt-link>
            </swiper-slide>
        </swiper>
    </div>
</template>